<template>
  <div class="d-flex flex-column align-items-center">
    <div class="about-us-page">
      <div class="d-flex flex-md-row flex-column-reverse align-items-center justify-content-between mb-3">
        <h1 class="about-us-page-title">
          Om Norsk Helseportal
        </h1>
        <!-- <img
          class="app-logo"
          :src="appLogo"
          alt="logo"
        > -->
      </div>
      <div class="main-content-block mb-5">
        <div class="d-flex flex-column flex-md-row info-row align-items-center justify-content-between">
          <div
            class="col-md-6 px-0 pr-md-2"
            @mouseover="controls1 = 1"
            @mouseleave="controls1 = 0"
          >
            <div class="videoWrapper">
              <div
                v-if="!playerActive['0']"
                class="video-player-actions d-flex align-items-center justify-content-center position-absolute"
              >
                <img
                  :src="playIcon"
                  alt="play-btn"
                  @click="play(0)"
                >
              </div>
              <div
                v-else
                class="pause-placeholder"
              >
                <div
                  class="video-player-actions pause align-items-center justify-content-center position-absolute"
                >
                  <img
                    :src="pauseIcon"
                    alt="play-btn"
                    @click="pause(0)"
                  >
                </div>
              </div>

              <vue-vimeo-player
                ref="player-0"
                class="rounded"
                :options="options"
                :video-url="videoID1"
                @ready="onReady(0)"
                @ended="ended(0)"
              />
            </div>
          </div>
          <div class="text-info-part col-md-6 px-0 pl-md-2 d-flex flex-column align-items-center">
            <h2
              class="mb-0"
            >
              Alt på ett sted
            </h2>
            <span />
            <h5 class="mb-0 mt-1 mt-md-0 text-center">
              Nå har legene i Norge fått sitt eget lukkede fagnettverk. Norsk Helseportal er legens karriereverktøy
              som forenkler og samler alle legene i Norge. Alt er på et trygt sted bare for leger.
              Alle profiler i nettverket er ekte identiteter og består kun av leger som verifiserer seg med BankID, og
              sjekkes opp mot Helsepersonellregisteret.
              Norsk Helseportal samler kurs, webinarer, podcaster, har egne fagforumer og et torg for medisinsk
              utstyr, en side for jobb annonser, pluss mye mer.

            </h5>
          </div>
        </div>
        <div class="d-flex info-row flex-column-reverse flex-md-row align-items-center justify-content-between">
          <div class="text-info-part col-md-6 px-0 d-flex pr-md-2 flex-column align-items-center">
            <h2 class="mb-0">
              Nettverk
            </h2>
            <span />
            <h5 class="mb-0 mt-1 mt-md-0 text-center">
              Ønsker du å få løpende oversikt over hvilke behandlingstilbud som finnes, og hvor du kan henvise?
              På Norsk Helseportal kan klinikker, sykehus, avtalespesialister, fastleger eller organisasjoner lage sin
              egen side gratis.
              På sidene kan de formidle nyttig informasjon, som for eksempel behandlingstilbud.
              I tillegg har hver side sin egen vegg. På veggen kan de legge ut nyheter.
              Du følger sidene du selv ønsker. Nyhetene kommer da direkte i din feed.
              På Norsk Helseportal bestemmer du selv din faglige nyhetsstrøm.
              Lag en gratis bruker på Norsk Helseportal - fagportalen for leger i Norge
            </h5>
          </div>
          <div
            class=" col-md-6 px-0 pl-md-2"
            @mouseover="controls2 = 1"
            @mouseleave="controls2 = 0"
          >
            <div class="videoWrapper">
              <div
                v-if="!playerActive['1']"
                class="video-player-actions d-flex align-items-center justify-content-center position-absolute"
              >
                <img
                  :src="playIcon"
                  alt="play-btn"
                  @click="play(1)"
                >
              </div>
              <div
                v-else
                class="pause-placeholder"
              >
                <div
                  class="video-player-actions pause align-items-center justify-content-center position-absolute"
                >
                  <img
                    :src="pauseIcon"
                    alt="play-btn"
                    @click="pause(1)"
                  >
                </div>
              </div>
              <vue-vimeo-player
                ref="player-1"
                :options="options"
                :video-id="videoID2"
                @ready="onReady(1)"
                @ended="ended(1)"
              />
            </div>
          </div>

        </div>
        <div class="d-flex info-row flex-column flex-md-row align-items-center justify-content-between">
          <div
            class=" col-md-6 px-0 pr-md-2"
            @mouseover="controls3 = 1"
            @mouseleave="controls3 = 0"
          >
            <div class="videoWrapper">
              <div
                v-if="!playerActive['2']"
                class="video-player-actions d-flex align-items-center justify-content-center position-absolute"
              >
                <img
                  :src="playIcon"
                  alt="play-btn"
                  @click="play(2)"
                >
              </div>
              <div
                v-else
                class="pause-placeholder"
              >
                <div
                  class="video-player-actions pause align-items-center justify-content-center position-absolute"
                >
                  <img
                    :src="pauseIcon"
                    alt="play-btn"
                    @click="pause(2)"
                  >
                </div>
              </div>
              <vue-vimeo-player
                ref="player-2"
                :options="options"
                :video-id="videoID3"
                @ready="onReady(2)"
                @ended="ended(2)"
              />
            </div>
          </div>
          <div class="text-info-part col-md-6 pl-md-2 px-0 d-flex flex-column align-items-center">
            <h2 class="mb-0">
              Markedsplass
            </h2>
            <span />
            <h5 class="mb-0 mt-1 mt-md-0 text-center">
              Har du et stetoskop, et blodtrykksapparat eller en doppler du ikke bruker?
              Har du en medisinsk benk stående gjemt inne på et lagerrom som bare tar opp plass?
              Hva med å legge det ut for salg?
              Nå har Norsk Helseportal laget et torg for medisinsk utstyr.
              Alle leger kan gratis legge ut annonser på torget.
              Lag en gratis bruker på Norsk Helseportal - fagportalen for leger i Norge.
            </h5>
          </div>
        </div>
        <div class="d-flex info-row flex-column-reverse flex-md-row align-items-center justify-content-between">
          <div class="text-info-part col-md-6 pr-md-2 px-0 d-flex flex-column align-items-center">
            <h2 class="mb-0">
              Jobb
            </h2>
            <span />
            <h5 class="mb-0 mt-1 mt-md-0 text-center">
              Skal du ansette en lege? Norsk Helseportal er en portal for leger med mange tusen medlemmer. Her samles legene for faglig utvikling og karrierebygging. På portalen finner legene logistikkløsninger for mye viktig informasjon. Portalen har blant annet et rekrutteringsverktøy for å finne den riktige legen. Norsk Helseportal har legene – har du annonsen?
            </h5>
          </div>
          <div
            class=" col-md-6 px-0 pl-md-2"
            @mouseover="controls4 = 1"
            @mouseleave="controls4 = 0"
          >
            <div class="videoWrapper">
              <div
                v-if="!playerActive['3']"
                class="video-player-actions d-flex align-items-center justify-content-center position-absolute"
              >
                <img
                  :src="playIcon"
                  alt="play-btn"
                  @click="play(3)"
                >
              </div>
              <div
                v-else
                class="pause-placeholder"
              >
                <div
                  class="video-player-actions pause align-items-center justify-content-center position-absolute"
                >
                  <img
                    :src="pauseIcon"
                    alt="play-btn"
                    @click="pause(3)"
                  >
                </div>
              </div>
              <vue-vimeo-player
                ref="player-3"
                :options="options"
                :video-id="videoID4"
                @ready="onReady(3)"
                @ended="ended(3)"
              />
            </div>
          </div>
        </div>
      </div>
      <h1 class="team-title mb-2">
        Vårt team
      </h1>
      <div class="d-flex flex-wrap">
        <div
          v-for="person in peopleBehindNHP"
          :key="person.id"
          class="team-member"
        >
          <img
            :src="person.image"
            alt=""
          >
          <h4>
            <strong>{{ person.name }}</strong>
          </h4>
          <h5 class="mb-0">
            {{ person.position }}
          </h5>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import appLogo from '@/assets/images/logo/norskhelse_logo.svg'
import playIcon from '@/assets/images/pages/home/play-icon.svg'
import pauseIcon from '@/assets/images/pages/home/pause-icon.svg'
import TronImage from '@/assets/images/pages/about-us/people-behind-NHP/Tron.jpeg'
import EirikSaltkjelImage from '@/assets/images/pages/about-us/people-behind-NHP/Eirik Saltkjel.jpeg'
import ChrisImage from '@/assets/images/pages/about-us/people-behind-NHP/Chris.jpeg'
import AllesioBWImage from '@/assets/images/pages/about-us/people-behind-NHP/Alessio-BW.png'
import MiguelImage from '@/assets/images/pages/about-us/people-behind-NHP/Miguel.jpeg'
import VegardImage from '@/assets/images/pages/about-us/people-behind-NHP/Vegard.jpeg'
import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
  components: { vueVimeoPlayer },
  data() {
    return {
      appLogo,
      playIcon,
      pauseIcon,
      videoID1: 'https://player.vimeo.com/video/814186659?h=ca68d64354&badge=0&autopause=0&player_id=0&app_id=58479',
      videoID2: '814188453',
      videoID3: '814188189',
      videoID4: '814189157',
      options: {
        responsive: true,
        controls: false,
      },
      playerReady: {
        0: false,
        1: false,
        2: false,
        3: false,
      },
      playerActive: {
        0: false,
        1: false,
        2: false,
        3: false,
      },
      peopleBehindNHP: [
        {
          id: 1,
          name: 'Tron Harald Gaasø',
          position: 'Styreleder, utdannet innenfor helseledelse, Key account manager i salg',
          image: TronImage,
        },
        {
          id: 2,
          name: 'Eirik Saltkjel',
          position: 'Styremedlem, tidligere sikkerhetssjef i Vipps, e-helsedirektoratet og Helse Midt',
          image: EirikSaltkjelImage,
        },
        {
          id: 3,
          name: 'Christopher John Roberts',
          position: 'Styremedlem, internasjonal bakgrunn innen Investment Banking',
          image: ChrisImage,
        },
        {
          id: 4,
          name: 'Alessio Attanasio',
          position: 'CDO, styremedlem, fotograf og utvikler av medisinske videoer',
          image: AllesioBWImage,
        },
        {
          id: 5,
          name: 'Vegard von Wachenfeldt',
          position: 'CEO, styremedlem, tidligere fastlege, kommuneoverlege, forsker og overlege i direktorat',
          image: VegardImage,
        },
        {
          id: 6,
          name: 'Miguel Johansen',
          position: 'CTO, utvikler',
          image: MiguelImage,
        },
      ],
    }
  },
  methods: {
    onReady(index) {
      this.playerReady[index] = true
    },
    play(index) {
      if (this.playerReady[index]) {
        this.playerActive = {
          0: false,
          1: false,
          2: false,
          3: false,
        }
        this.$refs[`player-${index}`].play()
        this.playerActive[index] = true
      }
    },
    pause(index) {
      this.$refs[`player-${index}`].pause()
      const array = this.playerActive
      array[index] = false
      this.playerActive = array
    },
    ended(index) {
      this.playerActive[index] = false
    },
  },
}

</script>
